<template>
    <v-card flat>
        <div>
        <v-container class="fill-height">
            <v-row
            v-for="n in 1"
            :key="n"
            :class="n === 1 ? 'mb-6' : ''"
            no-gutters>
            <v-col style="margin-right: 16px">
                <v-card class="fill-height" :shaped="true" :raised="true" style="padding-top: 20px; padding-bottom: 5px; max-height: 260px">
                    <v-row class="mb-6 align-center" no-gutters>
                        <v-col :md="3" class="text-center">
                            <div class="pa-2">
                            <div class="pa-3 mx-7" style="background: #FFFBED; width: 68px; height:68px; border-radius: 50%;">
                                <v-icon color="#00D79E" :x-large="true">$vuetify.icons.manOrange</v-icon>
                            </div>
                            <span class="font-weight-bold">{{ totalMember }}</span>
                            <v-spacer></v-spacer>
                            <span class="subtitle-2" style="color: #E8B600">Inactive Member</span>
                            <p class="caption" style="color: #D4D4D4">from last 1 month</p>
                            </div>
                        </v-col>
                        <v-col :md="6" >
                            <div v-for="(item, id) in dataCity" :key="id" style="min-height: 40px; margin-bottom: 8px">
                                <div style="margin-bottom: 5px">
                                    <span>{{ item.name }}</span>
                                    <span class="float-right">{{ item.fpercentage }}</span>
                                </div>
                                <div>
                                  <v-progress-linear :color="colorMember[0]" :value="item.percentage"></v-progress-linear>
                                </div>
                            </div>
                        </v-col>
                        <v-col :md="2" style="padding-left: 5px;">
                            <div v-for="(item, id) in dataCity" :key="id" class="text-right" style="height: 40px; margin-bottom: 8px">
                                <span style="color: #00D79E">{{ item.value }}</span>
                                <p class="caption" style="color: #BEC2C4"> Users </p>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col >
                <v-card class="fill-height pa-6" :shaped="true" :raised="true" style="max-height: 260px">
                    <span class="title" style="color: #E8B600;">Top Longest Inactive</span><br>
                    <div v-for="(item, id) in dataLongestInactive" :key="id" style="min-height: 32px;">
                    <v-row style="height: 32px">
                        <v-col>
                        <span class="subtitle-2">{{ item.name }}</span>
                        </v-col>
                        <v-col>
                        <p class="float-right subtitle-2" style="color: #888888">{{ item.long }} ago</p>
                        </v-col>
                    </v-row>
                    </div>
                </v-card>
            </v-col>
            </v-row>
            <v-card class="pa-2" :shaped="true" :raised="true" width="100%">
            <v-card-title style="height: 64px">
                <v-row class="mb-6" no-gutters>
                <v-col :md="6">
                    Member List
                </v-col>
                <v-col :md="4">
                    <div class="float-right" style="margin-right: 8px">
                    <v-menu v-model="menu1" :close-on-content-click="false" max-width="290" >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                        :value="computedDateFormattedMomentjs"
                        readonly
                        v-on="on"
                        solo
                        background-color="#F5F5F5"
                        style="width: 200px"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date"
                        @click:date="setDate(0)"
                        range
                    ></v-date-picker>
                    </v-menu>
                    </div>
                </v-col>
                <v-col :md="2">
                    <v-autocomplete
                    v-model="selectCity"
                    :items="dropdown_font"
                    solo
                    label="All City"
                    background-color="#F5F5F5"
                    ></v-autocomplete>
                </v-col>
                </v-row>
            </v-card-title>
                <div>
                <ve-line class="mx-6" :data="dataMemberList" :legend-visible="false" :colors="colorsLine" :settings="lineSettings" :extend="extend" :xAxis="xaxis" :after-config="afterConfig" />
                <v-card class="pa-2" :shaped="true" >
                    <v-card-title>
                    14232 Member
                    <v-spacer></v-spacer>
                    <v-btn rounded outlined style="margin-right: 8px"><v-icon left>$vuetify.icons.download</v-icon>Download</v-btn>
                    <v-btn rounded outlined style="margin-right: 8px"><v-icon left>$vuetify.icons.print</v-icon>Print</v-btn>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        style="width: 10px;"
                    ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :headers="headerMemberlist"
                        :items="dataMemberListDetail"
                        :search="search"
                        hide-default-footer
                        @click:row="clickStaff">
                        <template v-slot:header.location="{ header }">
                        <span style="color: #00B082">{{ header.text }}</span>
                        </template>
                        <template v-slot:item.name="{ item }">
                            <v-avatar size="36" style="margin-right: 10px">
                            <img
                                :src="item.photo"
                            >
                            </v-avatar>
                            {{ item.name }}
                        </template>
                        <template v-slot:item.member_status_desc="{ item }">
                            <!-- <div v-if="item.status === 1" style="width: 80px;color: #ffffff; background: transparent linear-gradient(180deg, #FFB144 0%, #FFCB56 100%) 0% 0% no-repeat padding-box; border-radius: 4px;">
                                Member
                            </div>
                            <div v-else style="width: 80px; color: #ffffff; background: #D4D4D4 0% 0% no-repeat padding-box; border-radius: 4px;">
                                Free
                            </div> -->
                            <v-btn v-if="item.member_status_desc === 'FREE'" depressed small style="width: 80px; color: #ffffff; background: #D4D4D4 0% 0% no-repeat padding-box;">{{ item.member_status_desc }}</v-btn>
                            <v-btn v-else depressed small style="width: 80px; color: #ffffff; background: transparent linear-gradient(180deg, #FFB144 0%, #FFCB56 100%) 0% 0% no-repeat padding-box;">{{ item.member_status_desc }}</v-btn>
                        </template>
                        </v-data-table>

                    <v-spacer></v-spacer><br>
                    <v-pagination
                    v-model="page"
                    color="#00B082"
                    :length="total_page"
                    :total-visible="7"
                    circle
                    ></v-pagination>
                </v-card>
                </div>
            </v-card>
        </v-container>
        </div>
    </v-card>
</template>

<script>
import moment from 'moment'
import echarts from 'echarts/lib/echarts'
import { fetchMemberInactive, fetchActivityMember, fetchLongestInactive, fetchMemberlistDetail } from '@/api/report-member'

export default {
  name: 'ListreportActivity',
  props: ['xaxis', 'extend', 'page', 'total_page'],

  data () {
    return {
      menu1: false,
      menu2: false,
      date: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      selectCity: '',
      dropdown_font: ['All City', 'Yogyakarta', 'Batam', 'Jakarta'],
      ringSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        offsetY: 70,
        radius: [40, 70],
        label: {
          normal: {
            show: false,
            position: 'center'
          },
          emphasis: {
            show: false,
            textStyle: {
              fontSize: '30',
              fontWeight: 'bold'
            }
          }
        }
      },
      colorsLine: ['#FDD648', '#1DA1F3'],
      lineSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#FDD648'
          }, {
            offset: 1,
            color: '#FDD64870'
          }])
        }
      },
      value1: [0, 10],
      min: 0,
      max: 24,
      search: '',
      dataCity: [
        {
          name: 'Yogyakarta',
          fpercentage: '40%',
          percentage: '40',
          value: 900
        },
        {
          name: 'Jakarta',
          fpercentage: '20%',
          percentage: '20',
          value: 900
        },
        {
          name: 'Bali',
          fpercentage: '20%',
          percentage: '20',
          value: 900
        },
        {
          name: 'Batam',
          fpercentage: '10%',
          percentage: '10',
          value: 900
        },
        {
          name: 'Other',
          fpercentage: '10%',
          percentage: '10',
          value: 900
        }
      ],
      // from api //////////////////////////////////////////////////////
      // //////////////////////////////////////////////////////////////
      colorsRing: ['#FFB144', '#D4D4D4', '#A1FFD1', '#C5DBFF'],
      dataMember: {},
      dataNewMember: {},
      dataMemberList: {},
      dataMemberListDetail: [],
      totalMember: 0,
      totalNewMember: 0,
      colorMember: ['#FFB144', '#BEC2C4'],
      title: {
        text: 0,
        textStyle: {
          color: '#333333',
          fontSize: '36',
          fontStyle: 'normal'
        },
        x: 'center',
        y: 'center'
      },
      headerMemberlist: [
        {
          text: 'Staff',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Age',
          align: 'center',
          value: 'age'
        },
        {
          text: 'Birthday Date',
          align: 'center',
          value: 'fbirthday'
        },
        {
          text: 'Time Joined',
          align: 'center',
          value: 'fcreated_time'
        },
        {
          text: 'Member Status',
          align: 'center',
          value: 'member_status_desc'
        }
      ],
      dataLongestInactive: {}
    }
  },

  mounted () {
    this.getInactiveMember()
    this.getMemberListDetail()
    this.getListActivityMember()
    this.getLongestInactive()
  },

  created () {
    this.formatter = value1 => `${value1}:00`
  },

  computed: {
    computedDateFormattedMomentjs () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date[0] ? moment(this.date[0]).format('D') : ''
      endDate = this.date[1] ? moment(this.date[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    }
  },

  methods: {
    setDate (param) {
      if (param === 0) {
        if (this.date.length > 1) {
          this.menu1 = false
        }
      } else {
        if (this.date2.length > 1) {
          this.menu2 = false
        }
      }
      this.getListActivityMember()
      this.getMemberListDetail()
    },
    afterConfig (options) {
      options.series[0].symbolSize = 16
      return options
    },
    rowClick (row) {
      this.$router.push('/report/report_merchant/2')
    },

    getInactiveMember () {
      // this.listLoading = true
      fetchMemberInactive().then((response) => {
        this.dataMember = {
          member: {
            name: 'Member',
            value: response.data.data.all_member.member.value,
            percentage: response.data.data.all_member.member.percentage,
            fpercentage: response.data.data.all_member.member.fpercentage
          },
          free: {
            name: 'Free',
            value: response.data.data.all_member.free.value,
            percentage: response.data.data.all_member.free.percentage,
            fpercentage: response.data.data.all_member.free.fpercentage
          }
        }
        this.totalMember = response.data.data.all_member.total_member
        this.totalNewMember = response.data.data.new_member.total_member
        this.title.text = response.data.data.new_member.total_member
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    getListActivityMember () {
      // this.listLoading = true
      var param = {
        start_date: this.date[0],
        end_date: this.date[1]
      }
      fetchActivityMember(param).then((response) => {
        console.log('resp', response)
        var row = []
        response.data.data.map(i => {
          row.push(i)
        })
        this.dataMemberList = {
          columns: ['fdate', 'count_by_date'],
          rows: row
        }
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    getLongestInactive () {
      // this.listLoading = true
      var param = {
        start_date: this.date[0],
        end_date: this.date[1]
      }
      fetchLongestInactive(param).then((response) => {
        console.log('resp', response)
        this.dataLongestInactive = response.data.data
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    getMemberListDetail () {
      // this.listLoading = true
      var param = {
        start_date: this.date[0],
        end_date: this.date[1],
        member_status_desc: 'member'
      }
      fetchMemberlistDetail(param).then((response) => {
        console.log('resp', response)
        this.dataMemberListDetail = response.data.data
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    }
  }
}
</script>

<style lang="scss">
.mytable .v-table tbody tr:not(:last-child) {
    border-bottom: none;
}
</style>

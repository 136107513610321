var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('div',[_c('v-container',{staticClass:"fill-height"},[_vm._l((1),function(n){return _c('v-row',{key:n,class:n === 1 ? 'mb-6' : '',attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"margin-right":"16px"}},[_c('v-card',{staticClass:"pa-2",attrs:{"width":"100%"}},[_c('v-card-title',{staticStyle:{"height":"64px"}},[_c('v-row',{staticClass:"mb-6",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":6}},[_vm._v(" Member List ")]),_c('v-col',{attrs:{"md":4}},[_c('div',{staticClass:"float-right",staticStyle:{"margin-right":"8px"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticStyle:{"width":"200px"},attrs:{"value":_vm.computedDateFormattedMomentjs,"readonly":"","solo":"","background-color":"#F5F5F5"}},on))]}}],null,true),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"range":""},on:{"click:date":function($event){return _vm.setDate(0)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)]),_c('v-col',{attrs:{"md":2}},[_c('v-autocomplete',{attrs:{"items":_vm.dropdown_font,"solo":"","label":"All City","background-color":"#F5F5F5"},model:{value:(_vm.selectCity),callback:function ($$v) {_vm.selectCity=$$v},expression:"selectCity"}})],1)],1)],1),_c('ve-line',{staticClass:"mx-6",attrs:{"data":_vm.dataMemberList,"legend-visible":false,"colors":_vm.colorsLine,"settings":_vm.lineSettings,"extend":_vm.extend,"xAxis":_vm.xaxis,"after-config":_vm.afterConfig}})],1)],1)],1)}),_c('v-card',{staticClass:"pa-2",attrs:{"shaped":true,"raised":true,"width":"100%"}},[_c('div',[_c('v-card-title',[_vm._v(" 14232 Member "),_c('v-spacer'),_c('v-btn',{staticStyle:{"margin-right":"8px"},attrs:{"rounded":"","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$vuetify.icons.download")]),_vm._v("Download")],1),_c('v-btn',{staticStyle:{"margin-right":"8px"},attrs:{"rounded":"","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$vuetify.icons.print")]),_vm._v("Print")],1),_c('v-text-field',{staticStyle:{"width":"10px"},attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headerMemberlist,"items":_vm.dataMemberListDetail,"search":_vm.search,"hide-default-footer":""},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"header.location",fn:function(ref){
var header = ref.header;
return [_c('span',{staticStyle:{"color":"#00B082"}},[_vm._v(_vm._s(header.text))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticStyle:{"margin-right":"10px"},attrs:{"size":"36"}},[_c('img',{attrs:{"src":item.photo}})]),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.member_status_desc",fn:function(ref){
var item = ref.item;
return [(item.member_status_desc === 'FREE')?_c('v-btn',{staticStyle:{"width":"80px","color":"#ffffff","background":"#D4D4D4 0% 0% no-repeat padding-box"},attrs:{"depressed":"","small":""}},[_vm._v(_vm._s(item.member_status_desc))]):_c('v-btn',{staticStyle:{"width":"80px","color":"#ffffff","background":"transparent linear-gradient(180deg, #FFB144 0%, #FFCB56 100%) 0% 0% no-repeat padding-box"},attrs:{"depressed":"","small":""}},[_vm._v(_vm._s(item.member_status_desc))])]}}])}),_c('v-spacer'),_c('br'),_c('v-pagination',{attrs:{"color":"#00B082","length":_vm.total_page,"total-visible":7,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-card flat>
        <div>
        <v-container class="fill-height">
            <v-row
            v-for="n in 1"
            :key="n"
            :class="n === 1 ? 'mb-6' : ''"
            no-gutters>
            <v-col style="margin-right: 16px">
                <v-card class="fill-height" :shaped="true" :raised="true" style="padding-top: 20px; padding-bottom: 5px; max-height: 260px">
                    <v-row class="mb-6 align-center" no-gutters>
                        <v-col :md="3" class="text-center mr-4">
                            <div class="pa-2">
                            <div class="pa-3 mx-7" style="background: #EBFCF7; width: 68px; height:68px; border-radius: 50%;">
                                <v-icon color="#00D79E" :x-large="true">$vuetify.icons.man</v-icon>
                            </div>
                            <span class="font-weight-bold">{{ totalMember }}</span>
                            <v-spacer></v-spacer>
                            <span class="body-2" style="color: #BEC2C4">Total Member</span>
                            </div>
                        </v-col>
                        <v-col :md="6" >
                            <div v-for="(item, id) in dataCity" :key="id" style="min-height: 40px; margin-bottom: 8px">
                                <div style="margin-bottom: 5px">
                                    <span>{{ item.name }}</span>
                                    <span class="float-right">{{ item.fpercentage }}</span>
                                </div>
                                <div>
                                  <v-progress-linear :color="colorMember[0]" :value="item.percentage"></v-progress-linear>
                                </div>
                                <!-- <div v-if="id === 'free'">
                                  <v-progress-linear :color="colorMember[1]" :value="item.percentage"></v-progress-linear>
                                </div> -->
                            </div>
                        </v-col>
                        <v-col :md="2">
                            <div v-for="(item, id) in dataCity" :key="id" class="text-right" style="height: 40px; margin-bottom: 8px">
                                <span style="color: #00D79E">{{ item.value }}</span>
                                <p class="caption" style="color: #BEC2C4"> Users </p>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col >
                <v-card class="align-center" :shaped="true" :raised="true" style="max-height: 260px">
                    <v-row no-gutters>
                        <v-col :md="6" class="mt-8" >
                            <div style="height: 250px; margin-left: 5px">
                            <ve-ring :data="dataNewCity" :title="title" height="180px" :legend-visible="false" :settings="ringSettings" :colors="colorsRing" />
                            </div>
                        </v-col>
                        <v-col :md="5" class="ml-2 mt-4" >
                            <span class="title">{{ totalNewMember }} New Member</span>
                            <v-spacer></v-spacer>
                            <p class="subtitle" style="color: #BEC2C4;">from last 7 days</p>
                            <div v-for="(item, id) in dataNewCity.rows" :key="id" style="min-height: 17px; margin-bottom: 8px">
                                <div><li :style="'color:' + colorsRing[id]">
                                    <span :style="'color:' + colorsRing[id]">{{ item.percentage }}%  </span>
                                    <span> {{ item.name }}</span>
                                    </li>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            </v-row>
            <v-card class="pa-2" :shaped="true" :raised="true" width="100%">
            <v-card-title style="height: 64px">
                <v-row class="mb-6" no-gutters>
                <v-col :md="5">
                    Member List
                </v-col>
                <v-col :md="2" class="mr-3">
                  <v-menu v-model="menu1" :close-on-content-click="false" max-width="290" >
                  <template v-slot:activator="{ on }">
                      <v-text-field
                      :value="computedDateFormattedMomentjs"
                      readonly
                      v-on="on"
                      solo
                      background-color="#F5F5F5"
                      style="width: 200px"
                      @click="dialog = true"
                      ></v-text-field>
                  </template>
                  <!-- <v-date-picker
                      v-model="date"
                      @click:date="setDate(0)"
                      range
                  ></v-date-picker> -->
                  </v-menu>
                </v-col>
                <v-col :md="2" class="mr-3">
                    <v-autocomplete
                    v-model="selectCity"
                    :items="dropdown_gender"
                    solo
                    label="Gender"
                    background-color="#F5F5F5"
                    ></v-autocomplete>
                </v-col>
                <v-col :md="2" class="mr-3">
                    <v-autocomplete
                    v-model="selectCity"
                    :items="dropdown_font"
                    solo
                    label="All City"
                    background-color="#F5F5F5"
                    ></v-autocomplete>
                </v-col>
                </v-row>
            </v-card-title>
              <div class="mt-8">
                <div class="float-left ml-6 mb-8 mt-8" style="position: relative; top: -90px">
                  <div class="float-left">
                    <span>Age Range</span>
                    <v-chip v-for="item in agerange" :key="item" class="ma-2" outlined @click="selectFilterRange(item)">{{ item }}</v-chip>
                  </div>
                </div>
                <ve-line class="mx-6" style="margin-top: 100px" :data="dataMemberList" :legend-visible="false" :colors="colorsLine" :settings="lineSettings" :extend="extend" :xAxis="xaxis" :after-config="afterConfig" />
                <v-card class="pa-2" :shaped="true" >
                    <v-card-title>
                    14232 Member
                    <v-spacer></v-spacer>
                    <v-btn rounded outlined style="margin-right: 8px"><v-icon left>$vuetify.icons.download</v-icon>Download</v-btn>
                    <v-btn rounded outlined style="margin-right: 8px"><v-icon left>$vuetify.icons.print</v-icon>Print</v-btn>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        style="width: 10px;"
                    ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :headers="headerMemberlist"
                        :items="dataMemberListDetail"
                        :search="search"
                        hide-default-footer
                        @click:row="clickStaff">
                        <template v-slot:header.location="{ header }">
                        <span style="color: #00B082">{{ header.text }}</span>
                        </template>
                        <template v-slot:item.name="{ item }">
                            <v-avatar size="36" style="margin-right: 10px">
                            <img
                                :src="item.photo"
                            >
                            </v-avatar>
                            {{ item.name }}
                        </template>
                        <template v-slot:item.member_status_desc="{ item }">
                            <!-- <div v-if="item.status === 1" style="width: 80px;color: #ffffff; background: transparent linear-gradient(180deg, #FFB144 0%, #FFCB56 100%) 0% 0% no-repeat padding-box; border-radius: 4px;">
                                Member
                            </div>
                            <div v-else style="width: 80px; color: #ffffff; background: #D4D4D4 0% 0% no-repeat padding-box; border-radius: 4px;">
                                Free
                            </div> -->
                            <v-btn v-if="item.member_status_desc === 'FREE'" depressed small style="width: 80px; color: #ffffff; background: #D4D4D4 0% 0% no-repeat padding-box;">{{ item.member_status_desc }}</v-btn>
                            <v-btn v-else depressed small style="width: 80px; color: #ffffff; background: transparent linear-gradient(180deg, #FFB144 0%, #FFCB56 100%) 0% 0% no-repeat padding-box;">{{ item.member_status_desc }}</v-btn>
                        </template>
                        </v-data-table>

                    <v-spacer></v-spacer><br>
                    <v-pagination
                    v-model="page"
                    color="#00B082"
                    :length="total_page"
                    :total-visible="7"
                    circle
                    ></v-pagination>
                </v-card>
                </div>
            </v-card>
        </v-container>
        </div>
        <v-dialog
            v-model="dialog"
            max-width="664"
          >
            <v-card class="pb-5">
              <!-- <v-card-title class="headline">Transaction Detail</v-card-title> -->
              <v-toolbar color="elevation-0">
                <v-toolbar-title class="ml-4 font-weight-bold">Joined Date Filter</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <div class="mx-6">
                  <div class="ml-2">
                    <v-row>
                      <v-col :md="6">
                        <v-date-picker
                            v-model="date"
                            :no-title="true"
                            range
                        ></v-date-picker>
                      </v-col>
                      <v-col >
                        <div class="float-right text-left">
                          <v-btn v-for="item in btn" :key="item.id" class="mr-2 mb-4" width="136" rounded color="#00D79E" :bottom="true" outlined retain-focus-on-click @click="selectRange(item.id)">{{ item.value }}</v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                  <div>
                    <v-btn width="100%" color="#00D79E" rounded style="color: #FFFFFF" @click="setDate(0)">Apply</v-btn>
                  </div>
              </div>
            </v-card>
          </v-dialog>
    </v-card>
</template>

<script>
import moment from 'moment'
import echarts from 'echarts/lib/echarts'
import { fetchMemberlist, fetchMember, fetchMemberlistDetail } from '@/api/report-member'

export default {
  name: 'ListreportMember',
  props: ['xaxis', 'extend', 'page', 'total_page'],

  data () {
    return {
      menu1: false,
      menu2: false,
      date: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      selectCity: '',
      dropdown_font: ['All City', 'Yogyakarta', 'Batam', 'Jakarta'],
      dropdown_gender: ['All', 'Male', 'Female'],
      ringSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        offsetY: 90,
        radius: [40, 70],
        label: {
          normal: {
            show: false,
            position: 'center'
          },
          emphasis: {
            show: false,
            textStyle: {
              fontSize: '30',
              fontWeight: 'bold'
            }
          }
        }
      },
      colorsLine: ['#00D79E', '#1DA1F3'],
      lineSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#00D79E'
          }, {
            offset: 1,
            color: '#BEF4E6'
          }])
        }
      },
      value1: [0, 10],
      min: 0,
      max: 99,
      search: '',
      dataCity: [
        {
          name: 'Yogyakarta',
          fpercentage: '40%',
          percentage: '40',
          value: 900
        },
        {
          name: 'Jakarta',
          fpercentage: '20%',
          percentage: '20',
          value: 900
        },
        {
          name: 'Bali',
          fpercentage: '20%',
          percentage: '20',
          value: 900
        },
        {
          name: 'Batam',
          fpercentage: '10%',
          percentage: '10',
          value: 900
        },
        {
          name: 'Other',
          fpercentage: '10%',
          percentage: '10',
          value: 900
        }
      ],
      dataNewCity: {
        columns: ['percentage', 'fpercentage'],
        rows: [
          {
            name: 'Yogyakarta',
            fpercentage: '40',
            percentage: 40,
            value: 900
          },
          {
            name: 'Jakarta',
            fpercentage: '20',
            percentage: 30,
            value: 900
          },
          {
            name: 'Bali',
            fpercentage: '20',
            percentage: 15,
            value: 900
          },
          {
            name: 'Batam',
            fpercentage: '10',
            percentage: 10,
            value: 900
          },
          {
            name: 'Other',
            fpercentage: '10',
            percentage: 5,
            value: 900
          }
        ]
      },
      selectFilter: 'Age',
      picker: new Date().toISOString().substr(0, 10),
      btn: [
        {
          id: 1,
          value: 'Last 7 Days'
        },
        {
          id: 2,
          value: 'Last month'
        },
        {
          id: 3,
          value: 'Last 2 months'
        },
        {
          id: 4,
          value: 'Last 3 months'
        },
        {
          id: 5,
          value: 'Last 6 months'
        },
        {
          id: 6,
          value: 'Last 1 year'
        },
        {
          id: 7,
          value: 'Last 2 years'
        },
        {
          id: 8,
          value: 'Last 3 years'
        }
      ],
      // from api //////////////////////////////////////////////////////
      // //////////////////////////////////////////////////////////////
      dialog: false,
      agerange: ['18-24', '25-34', '35-44', '45-54', '55-64', '65+'],
      tooltipMerge: false,
      enableCross: false,
      joinFilter: 'display: none',
      dropdown_item: [{ name: 'Age', id: 0 }, { name: 'Joined Date', id: 1 }],
      colorsRing: ['#6E50A7', '#A6CEE3', '#B2DF8A', '#33A02C', '#FB9A99'],
      dataMember: {},
      dataNewMember: {},
      dataMemberList: {},
      dataMemberListDetail: [],
      totalMember: 0,
      totalNewMember: 0,
      colorMember: ['#00D79E', '#BEC2C4'],
      title: {
        text: 0,
        textStyle: {
          color: '#333333',
          fontSize: '36',
          fontStyle: 'normal'
        },
        x: 'center',
        y: 'center'
      },
      headerMemberlist: [
        {
          text: 'Staff',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Age',
          align: 'center',
          value: 'age'
        },
        {
          text: 'Birthday Date',
          align: 'center',
          value: 'fbirthday'
        },
        {
          text: 'Time Joined',
          align: 'center',
          value: 'fcreated_time'
        },
        {
          text: 'Member Status',
          align: 'center',
          value: 'member_status_desc'
        }
      ]
    }
  },

  mounted () {
    this.getInfoMember()
    this.getMemberList()
    this.getMemberListDetail()
  },

  created () {
    this.formatter = value1 => `${value1}`
  },

  computed: {
    computedDateFormattedMomentjs () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date[0] ? moment(this.date[0]).format('D') : ''
      endDate = this.date[1] ? moment(this.date[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    }
  },

  methods: {
    setDate (param) {
      this.dialog = false
      if (param === 0) {
        if (this.date.length > 1) {
          this.menu1 = false
        }
      } else {
        if (this.date2.length > 1) {
          this.menu2 = false
        }
      }
      this.getMemberList()
      this.getMemberListDetail()
    },
    afterConfig (options) {
      options.series[0].symbolSize = 16
      return options
    },
    rowClick (row) {
      this.$router.push('/report/report_merchant/2')
    },

    getInfoMember () {
      // this.listLoading = true
      fetchMember().then((response) => {
        this.dataMember = {
          member: {
            name: 'Member',
            value: response.data.data.all_member.member.value,
            percentage: response.data.data.all_member.member.percentage,
            fpercentage: response.data.data.all_member.member.fpercentage
          },
          free: {
            name: 'Free',
            value: response.data.data.all_member.free.value,
            percentage: response.data.data.all_member.free.percentage,
            fpercentage: response.data.data.all_member.free.fpercentage
          }
        }
        this.totalMember = response.data.data.all_member.total_member
        this.totalNewMember = response.data.data.new_member.total_member
        this.title.text = response.data.data.new_member.total_member
        this.dataNewMember = {
          columns: ['percentage', 'fpercentage'],
          rows: [{ 'percentage': parseInt(response.data.data.new_member.member.percentage), 'fpercentage': response.data.data.new_member.member.percentage, 'name': 'Member' },
            { 'percentage': parseInt(response.data.data.new_member.free.percentage), 'fpercentage': response.data.data.new_member.free.percentage, 'name': 'Free' }]
        }
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    getMemberList () {
      // this.listLoading = true
      var param = {
        start_date: this.date[0],
        end_date: this.date[1]
      }
      fetchMemberlist(param).then((response) => {
        console.log('resp', response)
        var row = []
        response.data.data.map(i => {
          row.push(i)
        })
        this.dataMemberList = {
          columns: ['fdate', 'count_customer'],
          rows: row
        }
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    getMemberListDetail () {
      // this.listLoading = true
      var param = {
        start_date: this.date[0],
        end_date: this.date[1]
      }
      fetchMemberlistDetail(param).then((response) => {
        console.log('resp', response)
        this.dataMemberListDetail = response.data.data
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    selectFilterRange (data) {
      console.log('age', data)
    },

    selectRange (data) {
      console.log('range', data)
    }
  }
}
</script>

<style lang="scss">
.mytable .v-table tbody tr:not(:last-child) {
    border-bottom: none;
}
</style>

<template>
  <div>
    <v-container class="grey lighten-5 fill-height">
      <div style="background: #ffffff; box-shadow: 0px 0px 12px #0000000A; border-radius: 16px;">
        <v-tabs
            v-model="tab"
            align-with-title
            color="#00D79E"
            background-color="transparent">
            <v-tabs-slider color="#00D79E"></v-tabs-slider>

            <v-tab v-for="item in items" :key="item.id">
            {{ item.name }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <list-member :extend="extend" :xaxis="xAxis" :page="page" :total_page="total_page"></list-member>
          </v-tab-item>
          <v-tab-item>
              <list-activity :extend="extend" :xaxis="xAxis" :page="page" :total_page="total_page"></list-activity>
          </v-tab-item>
          <v-tab-item>
              <list-transaction :extend="extend" :xaxis="xAxis" :page="page" :total_page="total_page"></list-transaction>
          </v-tab-item>
          <!-- <v-tab-item>
            <list-acqusition :extend="extend" :xaxis="xAxis" :page="page" :total_page="total_page"></list-acqusition>
          </v-tab-item> -->
        </v-tabs-items>
      </div>
    </v-container>
  </div>
</template>

<script>
import ListMember from './_list-member'
import ListActivity from './_list-activity'
import ListTransaction from './_list-transaction'

export default {
  name: 'ReportMember',
  components: {
    ListMember,
    ListActivity,
    ListTransaction
  },

  data () {
    return {
      tab: null,
      items: [
        {
          id: 0,
          name: 'Member List'
        },
        {
          id: 1,
          name: 'Activity'
        },
        {
          id: 2,
          name: 'Transaction'
        }
      ]
    }
  }
}
</script>
